import React from 'react'
import ReactDOM from 'react-dom/client'
import axios from 'axios'
import generateEnv from './env/generateEnv.js'
import { CenteredDiv, WtxColors, SetupProvider, createWtxAuthInstance } from '@wavetronix/common-components'
import MainPage from './components/MainPage'
import './index.css'

axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

if (window.location.pathname === '/logout') {
  window.localStorage.clear()
  window.location.href = window.location.origin
}

export const env = await generateEnv()

const root = ReactDOM.createRoot(document.getElementById('root'))

if (env.isError) {
  root.render(
    <CenteredDiv>
      <h3 style={{ color: WtxColors.INNOVATION_RED }}>{`${env.message}`}</h3>
    </CenteredDiv>
  )
} else {
  const msalInstance = await createWtxAuthInstance(env)

  document.getElementById('title').innerHTML = 'Nexus Configuration'

  root.render(
    <SetupProvider env={env} msalInstance={msalInstance} allowedRoles={['Gojira Team Member']}>
      <MainPage />
    </SetupProvider>
  )
}
