import React, { useEffect, useMemo, useState } from 'react'
import { TextField } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { PrimaryButton, SlideUpDialog, CustomSelect } from '@wavetronix/common-components'

const classes = {
  textField: {
    width: '80%',
    marginBottom: '30px'
  }
}

const URL_TYPE_LIST = ['webui', 'webapi']

const emptyHealthCheckUrl = {
  name: '',
  description: '',
  type: 'webapi',
  urlDev: '',
  urlInt: '',
  urlProd: ''
}

export default function HealthCheckUrlModal({ healthCheckUrl, mode, open, onClose, onSave }) {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedUrl, setSelectedUrl] = useState(emptyHealthCheckUrl)

  const title = useMemo(() => {
    return mode === 'create' ? 'Add Health Check URL' : 'Edit Health Check URL'
  }, [mode])

  useEffect(() => {
    if (healthCheckUrl) setSelectedUrl(healthCheckUrl)
  }, [healthCheckUrl])

  function stringNotNull(v) {
    return v ? v : ''
  }

  if (!selectedUrl) {
    return <div></div>
  }

  return (
    <SlideUpDialog
      maxWidth='md'
      open={open}
      onClose={() => {
        if (isBusy === false) {
          onClose()
        }
      }}
      title={<h3 style={{ margin: 0 }}>{title}</h3>}
      actions={
        <>
          <PrimaryButton
            style={{ marginRight: 15 }}
            disabled={isBusy}
            onClick={async () => {
              setIsBusy(true)
              await onSave(selectedUrl)
              setIsBusy(false)
              onClose()
            }}>
            &nbsp;{'Save'}
          </PrimaryButton>

          <PrimaryButton
            style={{ marginRight: 15 }}
            disabled={isBusy}
            onClick={async () => {
              onClose()
            }}>
            &nbsp;{'Cancel'}
          </PrimaryButton>
        </>
      }>
      <DialogContent>
        <div style={{ margin: '40px' }}>
          <TextField
            size='small'
            label={'Name'}
            sx={classes.textField}
            value={stringNotNull(selectedUrl.name)}
            onChange={e => {
              setSelectedUrl({ ...selectedUrl, name: e.target.value })
            }}
          />

          <TextField
            size='small'
            label={'Description'}
            sx={classes.textField}
            value={stringNotNull(selectedUrl.description)}
            onChange={e => {
              setSelectedUrl({ ...selectedUrl, description: e.target.value })
            }}
          />

          <CustomSelect
            style={classes.textField}
            label='Type'
            onChange={e => setSelectedUrl({ ...selectedUrl, type: e.target.value })}
            value={stringNotNull(selectedUrl.type)}
            options={URL_TYPE_LIST}
          />

          <TextField
            size='small'
            label={'Dev URL'}
            sx={classes.textField}
            value={stringNotNull(selectedUrl.urlDev)}
            onChange={e => {
              setSelectedUrl({ ...selectedUrl, urlDev: e.target.value })
            }}
          />

          <TextField
            size='small'
            label={'Int URL'}
            sx={classes.textField}
            value={stringNotNull(selectedUrl.urlInt)}
            onChange={e => {
              setSelectedUrl({ ...selectedUrl, urlInt: e.target.value })
            }}
          />

          <TextField
            size='small'
            label={'Prod URL'}
            sx={classes.textField}
            value={stringNotNull(selectedUrl.urlProd)}
            onChange={e => {
              setSelectedUrl({ ...selectedUrl, urlProd: e.target.value })
            }}
          />
        </div>
      </DialogContent>
    </SlideUpDialog>
  )
}
