import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class RemindersApi {
  getReminders = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    const baseURL = `${env.urls.remindersURL}/api/reminder`

    return (
      await axios.get(baseURL, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
    ).data
  }

  deleteReminder = async (msalInstance, accounts, id) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    const baseURL = `${env.urls.remindersURL}/api/reminder/${id}`

    await axios.delete(baseURL, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }

  addReminder = async (msalInstance, accounts, reminder) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    const baseURL = `${env.urls.remindersURL}/api/reminder`

    await axios.post(baseURL, reminder, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }

  updateReminder = async (msalInstance, accounts, reminder) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    const baseURL = `${env.urls.remindersURL}/api/reminder`

    await axios.put(baseURL, reminder, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }

  processReminders = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    const baseURL = `${env.urls.remindersURL}/api/reminder/remind`

    await axios.post(baseURL, null, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }

  validateServiceConfig = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    const baseURL = `${env.urls.remindersURL}/api/reminder/validate`

    await axios.get(baseURL, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new RemindersApi()
  }
  return instance
}

export default getInstance()
