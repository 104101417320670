import React, { useState, useMemo } from 'react'
import {
  CustomSelect,
  RegularButton,
  getResourceUrl,
  CenteredDiv,
  WtxColors,
  DeleteButton,
  SnackbarVariants,
  ErrorMessage
} from '@wavetronix/common-components'
import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { IconButton, CircularProgress, Grid } from '@mui/material'
import NexusAssetsApi from '../../api/NexusAssetsApi'
import { AddOutlined } from '@mui/icons-material'
import SquareIcon from '@mui/icons-material/Square'
import AddAssetModal from './AddAssetModal'
import DeleteConfirmationModal from '../DeleteConfirmationModal'
import { useSnackbar } from 'notistack'
import { ENVIRONMENT_MAP, ENVIRONMENT_LIST } from '../../utils/environmentUtils.js'
import { NexusAssetList } from './NexusAssetList'

export default function NexusAssetsPage() {
  const { instance, accounts } = useMsal()
  const [environment, setEnvironment] = useState('')
  const [folder, setFolder] = useState('')
  const [previewImage, setPreviewImage] = useState('')
  const [previewBackground, setPreviewBackground] = useState('white')
  const [openAssetModal, setOpenAssetModal] = useState(false)
  const [openDeleteAssetModal, setOpenDeleteAssetModal] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const baseURL = useMemo(() => {
    return ENVIRONMENT_MAP[environment]
  }, [environment])

  const {
    data: assetsData,
    isLoading: assetsLoading,
    refetch: assetsRefetch,
    error: assetsError
  } = useQuery({
    queryKey: ['assets', baseURL, folder],
    queryFn: async () => await NexusAssetsApi.getAssets(instance, accounts, baseURL, folder),
    enabled: !!baseURL && baseURL !== '' && !!folder && folder !== '' // Set 'enabled' to false if the parameter is null or an empty string
  })

  const deleteAsset = async () => {
    if (previewImage) {
      await NexusAssetsApi.deleteAsset(instance, accounts, baseURL, folder, previewImage)
        .then(async response => {
          enqueueSnackbar('Asset delete succeeded', SnackbarVariants.SUCCESS)
        })
        .catch(error => {
          enqueueSnackbar(`Asset delete - ${error.message}`, SnackbarVariants.ERROR)
        })
    }
  }

  return (
    <>
      <AddAssetModal
        open={openAssetModal}
        onClose={() => setOpenAssetModal(false)}
        assetsRefetch={assetsRefetch}
        baseURL={baseURL}
        folder={folder}
      />
      <DeleteConfirmationModal
        message={
          <>
            <p>Verify that you want to delete this asset:</p>
            <p>{previewImage}</p>
            <p style={{ color: WtxColors.INNOVATION_RED }}>
              Important: Any UIs using this asset value will show preview text instead on the image.
            </p>
          </>
        }
        open={openDeleteAssetModal}
        onClose={() => setOpenDeleteAssetModal(false)}
        onDelete={async () => {
          await deleteAsset()
          assetsRefetch()
          setOpenDeleteAssetModal(false)
          setPreviewImage('')
        }}
      />
      {/* Need to show cards for each environment and be able to add, remove, and edit each */}
      <div style={{ margin: '10px', width: '100%' }}>
        <Grid container>
          <Grid item md={6}>
            <CustomSelect
              style={{ width: '30%' }}
              label='Folder'
              onChange={e => {
                setPreviewImage('')
                setFolder(e.target.value)
              }}
              value={folder}
              options={['apps', 'products']}
            />
            <CustomSelect
              style={{ width: '30%' }}
              label='Environment'
              onChange={e => {
                setPreviewImage('')
                setEnvironment(e.target.value)
              }}
              value={environment}
              options={ENVIRONMENT_LIST}
            />
          </Grid>
          {environment ? (
            <Grid item md={6}>
              <RegularButton
                startIcon={<AddOutlined />}
                style={{ float: 'right' }}
                onClick={() => {
                  setOpenAssetModal(true)
                }}>
                Add Asset
              </RegularButton>
            </Grid>
          ) : null}
        </Grid>

        <div>
          {assetsError ? (
            <div style={{ margin: '10px', width: '100%' }}>
              <CenteredDiv>
                <ErrorMessage error={assetsError} />
              </CenteredDiv>
            </div>
          ) : null}
          {assetsLoading ? (
            <div style={{ margin: '10px', width: '100%' }}>
              <CenteredDiv>
                <CircularProgress />
              </CenteredDiv>
            </div>
          ) : null}
          {!assetsError && !assetsLoading ? (
            assetsData && assetsData.length > 0 ? (
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <NexusAssetList
                    assetsData={assetsData}
                    assetsLoading={assetsLoading}
                    onClick={value => {
                      setPreviewImage(value)
                    }}
                    environment={environment}
                    folder={folder}
                  />
                </Grid>
                <Grid item md={6}>
                  <CenteredDiv>
                    <h4>Asset Preview</h4>
                  </CenteredDiv>

                  <CenteredDiv>
                    {previewImage && previewImage !== '' ? (
                      <div style={{ display: 'block' }}>
                        <CenteredDiv>
                          <img
                            alt={previewImage}
                            style={{
                              maxWidth: '12em',
                              maxHeight: '12em',
                              backgroundColor: previewBackground
                            }}
                            src={`${getResourceUrl(
                              environment === 'local' ? 'dev' : environment
                            )}/images/${folder}/${previewImage}`}
                          />
                        </CenteredDiv>
                        <CenteredDiv>
                          <div>{previewImage}</div>
                        </CenteredDiv>
                        <div style={{ marginTop: '15px' }}>
                          <CenteredDiv>
                            <IconButton onClick={() => setPreviewBackground('white')}>
                              <SquareIcon sx={{ color: 'white', border: '1px solid black' }} />
                            </IconButton>
                            <IconButton onClick={() => setPreviewBackground('black')}>
                              <SquareIcon sx={{ color: 'black', border: '1px solid black' }} />
                            </IconButton>
                          </CenteredDiv>
                        </div>

                        <CenteredDiv>Can't see the image? Try changing the background color.</CenteredDiv>
                        <CenteredDiv>
                          <DeleteButton style={{ margin: '15px', width: '100%' }} onClick={() => setOpenDeleteAssetModal(true)}>
                            Delete Asset
                          </DeleteButton>
                        </CenteredDiv>
                      </div>
                    ) : (
                      'Select image to preview'
                    )}
                  </CenteredDiv>
                </Grid>
              </Grid>
            ) : (
              <h1
                style={{
                  margin: 'auto',
                  textAlign: 'center',
                  padding: 15,
                  marginTop: 15
                }}>
                Select an environment.
              </h1>
            )
          ) : null}
        </div>
      </div>
    </>
  )
}
