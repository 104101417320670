import React, { useEffect, useMemo, useState } from 'react'
import { TextField } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { PrimaryButton, SlideUpDialog, CustomSelect } from '@wavetronix/common-components'

const classes = {
  textField: {
    width: '80%',
    marginBottom: '30px'
  }
}

function isNullOrWhiteSpace(s) {
  let r = !s || s === null || s === '' || /^\s*$/.test(s)
  return r
}

const CATEGORY_LIST = ['Secret', 'Certificate']

const emptyReminder = {
  id: '',
  key: '',
  name: '',
  description: '',
  expireDate: '',
  howToUrl: '',
  category: CATEGORY_LIST[0]
}

export default function ExpirationReminderModal({ reminder, mode, open, onClose, onSave }) {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedReminder, setSelectedReminder] = useState(emptyReminder)
  const [selectedDate, setSelectedDate] = useState(dayjs())
  const [reminderIsValid, setReminderIsValid] = useState(false)

  const title = useMemo(() => {
    return mode === 'create' ? 'Add Reminder' : 'Edit Reminder'
  }, [mode])

  useEffect(() => {
    if (reminder) {
      setSelectedReminder(reminder)
      setSelectedDate(dayjs(reminder.expireDate))
    }
  }, [reminder])

  useEffect(() => {
    let isValid =
      selectedReminder &&
      !isNullOrWhiteSpace(selectedReminder.name) &&
      !isNullOrWhiteSpace(selectedReminder.description) &&
      !isNullOrWhiteSpace(selectedReminder.category)
    setReminderIsValid(isValid)
  }, [selectedReminder])

  function stringNotNull(v) {
    return v ? v : ''
  }

  if (!selectedReminder) {
    return <div></div>
  }

  return (
    <SlideUpDialog
      maxWidth='md'
      open={open}
      onClose={() => {
        if (isBusy === false) {
          onClose()
        }
      }}
      title={<h3 style={{ margin: 0 }}>{title}</h3>}
      actions={
        <>
          <PrimaryButton
            style={{ marginRight: 15 }}
            disabled={isBusy || !reminderIsValid}
            onClick={async () => {
              setIsBusy(true)
              selectedReminder.expireDate = selectedDate.format('YYYY-MM-DD')
              await onSave(selectedReminder)
              setIsBusy(false)
              onClose()
            }}>
            &nbsp;{'Save'}
          </PrimaryButton>

          <PrimaryButton
            style={{ marginRight: 15 }}
            disabled={isBusy}
            onClick={async () => {
              onClose()
            }}>
            &nbsp;{'Cancel'}
          </PrimaryButton>
        </>
      }>
      <DialogContent>
        <div style={{ margin: '40px' }}>
          <TextField
            size='small'
            label={'Name'}
            sx={classes.textField}
            value={stringNotNull(selectedReminder.name)}
            onChange={e => {
              setSelectedReminder({ ...selectedReminder, name: e.target.value })
            }}
          />

          <TextField
            size='small'
            label={'Description'}
            sx={classes.textField}
            value={stringNotNull(selectedReminder.description)}
            onChange={e => {
              setSelectedReminder({ ...selectedReminder, description: e.target.value })
            }}
          />

          <CustomSelect
            style={classes.textField}
            label='Type'
            onChange={e => setSelectedReminder({ ...selectedReminder, category: e.target.value })}
            value={stringNotNull(selectedReminder.category)}
            options={CATEGORY_LIST}
          />

          <TextField
            size='small'
            label={'How To URL'}
            sx={classes.textField}
            value={stringNotNull(selectedReminder.howToUrl)}
            onChange={e => {
              setSelectedReminder({ ...selectedReminder, howToUrl: e.target.value })
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={selectedDate}
              onChange={newValue => setSelectedDate(newValue)}
              showDaysOutsideCurrentMonth
              fixedWeekNumber={6}
              views={['year', 'month', 'day']}
            />
          </LocalizationProvider>
        </div>
      </DialogContent>
    </SlideUpDialog>
  )
}
