import React, { useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { MenuAppBar, NotFoundPage } from '@wavetronix/common-components'
import { env } from '../index.js'
import NexusConfigPage from './NexusConfig/NexusConfigPage'
import UICardPage from './UICardManagement/UICardPage'
import NexusAssetsPage from './NexusAssets/NexusAssetsPage'
import HealthStatusPage from './HealthStatus/HealthStatusPage.jsx'
import PageNavbar from './PageNavbar'
import version from '../env/version.json'
import ExpirationRemindersPage from './ExpirationReminders/ExpirationRemindersPage.jsx'

export const URL_MAP = {
  configuration: 0,
  cards: 1,
  assets: 2,
  healthstatus: 3,
  reminders: 4,
  '': 0
}

export default function MainPage() {
  const [state, setState] = useState(window.location.pathname.substring(1))

  return (
    <div id='content'>
      <MenuAppBar env={env} />
      <BrowserRouter>
        <Routes>
          <Route
            path='/configuration'
            element={
              <div style={{ display: 'flex', margin: '10px' }} id='content'>
                <PageNavbar value={URL_MAP[state]} onChangeCallback={path => setState(path)} />
                <NexusConfigPage />
              </div>
            }
          />
          <Route
            path='/cards'
            element={
              <div style={{ display: 'flex', margin: '10px' }} id='content'>
                <PageNavbar
                  value={URL_MAP[state]}
                  onChangeCallback={path => {
                    setState(path)
                  }}
                />
                <UICardPage />
              </div>
            }
          />
          <Route
            path='/assets'
            element={
              <div style={{ display: 'flex', margin: '10px' }} id='content'>
                <PageNavbar
                  value={URL_MAP[state]}
                  onChangeCallback={path => {
                    setState(path)
                  }}
                />
                <NexusAssetsPage />
              </div>
            }
          />
          <Route
            path='/healthstatus'
            element={
              <div style={{ display: 'flex', margin: '10px' }} id='content'>
                <PageNavbar
                  value={URL_MAP[state]}
                  onChangeCallback={path => {
                    setState(path)
                  }}
                />
                <HealthStatusPage />
              </div>
            }
          />
          <Route
            path='/reminders'
            element={
              <div style={{ display: 'flex', margin: '10px' }} id='content'>
                <PageNavbar
                  value={URL_MAP[state]}
                  onChangeCallback={path => {
                    setState(path)
                  }}
                />
                <ExpirationRemindersPage />
              </div>
            }
          />
          <Route
            path='/version'
            element={
              <div style={{ display: 'flex', margin: '10px' }} id='content'>
                {version.version}
              </div>
            }
          />
          <Route path='/' element={<Navigate to='/configuration' />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}
