import { Tab, Tabs } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { WtxColors } from '@wavetronix/common-components'

const classes = {
  tab: {
    textTransform: 'none',
    fontFamily: 'Klavika',
    fontSize: '16px',
    alignItems: 'end',
    padding: '0px 16px 0px 0px',
    color: 'black'
  },
  tabParent: {
    width: '210px'
  }
}

export default function PageNavbar(props) {
  const navigate = useNavigate()

  const onChange = path => {
    if (window.location.pathname !== `/${path}`) {
      navigate(`/${path}`)
    }
    props.onChangeCallback(path)
  }

  return (
    <Tabs
      value={props.value}
      TabIndicatorProps={{
        sx: {
          backgroundColor: WtxColors.IQ_BLUE
        }
      }}
      orientation='vertical'
      sx={classes.tabParent}>
      <Tab sx={classes.tab} label='Configuration' onClick={() => onChange('configuration')} />
      <Tab sx={classes.tab} label='UI Cards' onClick={() => onChange('cards')} />
      <Tab sx={classes.tab} label='Nexus Assets' onClick={() => onChange('assets')} />
      <Tab sx={classes.tab} label='Health Status' onClick={() => onChange('healthstatus')} />
      <Tab sx={classes.tab} label='Expiration Reminders' onClick={() => onChange('reminders')} />
    </Tabs>
  )
}
