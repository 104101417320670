import { TextField, IconButton } from '@mui/material'
import { DeleteOutline } from '@mui/icons-material'
import { useState, useMemo } from 'react'
import DeleteConfirmationModal from '../DeleteConfirmationModal'
import { WtxColors } from '@wavetronix/common-components'

const classes = {
  textField: {
    width: '44%',
    margin: '5px'
  }
}

export default function NexusConfigLine({ info, environment, setEnvInfo, property, textFieldCheck }) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const errorKey = useMemo(() => {
    if (info) {
      return info[0] === ''
    }
  }, [info])

  const errorValue = useMemo(() => {
    if (info) {
      return textFieldCheck(info[1])
    }
  }, [info, textFieldCheck])

  const deleteValue = () => {
    setEnvInfo(f => {
      var replaceKey = { ...f[environment][property] }
      delete replaceKey[`${info[0]}`]
      return {
        ...f,
        [environment]: {
          ...f[environment],
          [property]: { ...replaceKey }
        }
      }
    })
  }
  return (
    <>
      <DeleteConfirmationModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        message={
          <>
            <p>Verify that you want to delete this configuration information.</p>

            <p>
              {info[0]} : {info[1]}
            </p>
            <p>
              You can reload the page to repopulate it prior to saving changes. Once DELETED and SAVED this information must be
              manually re-added.
            </p>

            <p style={{ color: WtxColors.INNOVATION_RED }}>
              Important: Any UIs using this configuration value will throw errors upon deletion!
            </p>
          </>
        }
        onDelete={() => {
          setDeleteModalOpen(false)
          deleteValue()
        }}
      />
      <div>
        <TextField
          error={errorKey}
          size='small'
          sx={classes.textField}
          value={info[0]}
          onChange={e => {
            setEnvInfo(f => {
              var replaceKey = { ...f[environment][property] }
              delete Object.assign(replaceKey, { [e.target.value]: replaceKey[info[0]] })[`${info[0]}`]

              return {
                ...f,
                [environment]: {
                  ...f[environment],
                  [property]: { ...replaceKey }
                }
              }
            })
          }}
        />
        <TextField
          error={errorValue}
          size='small'
          sx={classes.textField}
          value={info[1]}
          onChange={e => {
            setEnvInfo(f => ({
              ...f,
              [environment]: {
                ...f[environment],
                [property]: { ...f[environment][property], [info[0]]: e.target.value }
              }
            }))
          }}
        />
        <IconButton
          onClick={() => {
            if (errorKey || errorValue) {
              deleteValue()
            } else setDeleteModalOpen(true)
          }}>
          <DeleteOutline />
        </IconButton>
      </div>
    </>
  )
}
