import React, { useState, useEffect } from 'react'
import { getResourceUrl, CenteredDiv } from '@wavetronix/common-components'
import { Box, ImageList, ImageListItem } from '@mui/material'

const BREAKPOINTS = { xs: 400, sm: 600, md: 960, lg: 1200, xl: 1536 }
const COLUMNS = { xs: 1, sm: 1, md: 2, lg: 3, xl: 5 }

const classes = {
  imageListItemStyle: {
    width: '100%',
    backgroundColor: 'white',
    cursor: 'pointer',
    borderRadius: '15px',
    '&:hover': {
      boxShadow: 8,
      opacity: 0.8
    }
  }
}

export const NexusAssetList = React.forwardRef(
  (
    { assetsData, onClick, environment, folder, height = '80vh', width = '60%', breakPoints = BREAKPOINTS, cols = COLUMNS },
    ref
  ) => {
    const [numCols, setNumCols] = useState(5)

    useEffect(() => {
      const breakpoints = { ...BREAKPOINTS, ...breakPoints }
      const columns = { ...COLUMNS, ...cols }
      const handleResize = () => {
        // Update the number of columns based on window width
        if (window.innerWidth < breakpoints.sm) {
          setNumCols(columns.xs)
        } else if (window.innerWidth < breakpoints.md) {
          setNumCols(columns.sm)
        } else if (window.innerWidth < breakpoints.lg) {
          setNumCols(columns.md)
        } else if (window.innerWidth < breakpoints.xl) {
          setNumCols(columns.lg)
        } else {
          setNumCols(columns.xl)
        }
      }
      // Add an event listener to handle window resizing
      window.addEventListener('resize', handleResize)

      // Initialize the number of columns based on the initial window size
      handleResize()

      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [breakPoints, cols])

    return (
      <Box sx={{ height: height, overflowY: 'scroll' }}>
        {assetsData ? (
          <ImageList sx={{ padding: '15px' }} cols={numCols} gap={15}>
            {assetsData.map((asset, id) => {
              return (
                <ImageListItem key={id} sx={classes.imageListItemStyle} onClick={() => onClick(asset)}>
                  <CenteredDiv>
                    <img
                      style={{
                        width: width,
                        height: 'auto',
                        objectFit: 'contain',
                        cursor: 'pointer',
                        marginTop: '15px'
                      }}
                      srcSet={`${getResourceUrl(
                        environment === 'local' ? 'dev' : environment
                      )}/images/${folder}/${asset}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      src={`${getResourceUrl(
                        environment === 'local' ? 'dev' : environment
                      )}/images/${folder}/${asset}?w=248&fit=crop&auto=format`}
                      alt={asset}
                      loading='lazy'
                    />
                  </CenteredDiv>
                </ImageListItem>
              )
            })}
          </ImageList>
        ) : (
          'No assets'
        )}
      </Box>
    )
  }
)
