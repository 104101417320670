import * as React from 'react'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { Tooltip, IconButton } from '@mui/material'

export default function URLHelperIcon({ message }) {
  return (
    <Tooltip title={message} placement='right'>
      <IconButton>
        <HelpOutlineOutlinedIcon />
      </IconButton>
    </Tooltip>
  )
}
