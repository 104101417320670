import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { CustomSelect } from '@wavetronix/common-components'
import HealthCheckBuilds from './HealthCheckBuilds'
import HealthCheckUrls from './HealthCheckUrls'
import HealthCheckOtherOptions from './HealthCheckOtherOptions'

const STATUS_TYPE_LIST = ['Builds', 'URLs', 'Other Options']

export default function HealthStatusPage() {
  const [statusType, setStatusType] = useState('')

  return (
    <div style={{ margin: '10px', width: '100%' }}>
      <div>
        <Grid item md={6} sm={12}>
          <CustomSelect
            style={{ width: '30%' }}
            label='Status Type'
            onChange={e => setStatusType(e.target.value)}
            value={statusType}
            options={STATUS_TYPE_LIST}
          />
        </Grid>
      </div>

      <div style={{ margin: '10px' }}>
        {statusType === 'Builds' ? <HealthCheckBuilds /> : <></>}
        {statusType === 'URLs' ? <HealthCheckUrls /> : <></>}
        {statusType === 'Other Options' ? <HealthCheckOtherOptions /> : <></>}
      </div>
    </div>
  )
}
