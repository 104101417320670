import React, { useState } from 'react'
import { CircularProgress, Tooltip, IconButton } from '@mui/material'
import { DeleteOutlined, EditOutlined } from '@mui/icons-material'
import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { CustomDataGrid, SnackbarVariants, CenteredDiv, ErrorMessage, PrimaryButton } from '@wavetronix/common-components'
import HealthChecksApi from '../../api/HealthChecksApi'
import HealthCheckUrlModal from './HealthCheckUrlModal'
import { useSnackbar } from 'notistack'

export default function HealthCheckUrls() {
  const { instance, accounts } = useMsal()
  const [healthCheckUrlModalOpen, setHealthCheckUrlModalOpen] = useState(false)
  const [selectedUrl, setSelectedUrl] = useState({})
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: urlData,
    isLoading: urlsLoading,
    refetch: urlsRefetch,
    error: urlsError
  } = useQuery({ queryKey: ['urls'], queryFn: async () => await HealthChecksApi.getUrls(instance, accounts) })

  const deleteUrl = async id => {
    try {
      await HealthChecksApi.deleteUrl(id)
      enqueueSnackbar(`HealthCheck URL deleted`, SnackbarVariants.SUCCESS)
      urlsRefetch()
    } catch (error) {
      enqueueSnackbar(`Delete failed`, SnackbarVariants.ERROR)
      console.error(error)
    }
  }

  const editUrl = async id => {
    const url = urlData.filter(u => u.id === id)[0]
    setSelectedUrl(url)
    setHealthCheckUrlModalOpen(true)
  }

  const saveUrl = async url => {
    try {
      let newUrl = {
        ...url,
        urls: {
          dev: url.urlDev,
          int: url.urlInt,
          prod: url.urlProd
        }
      }
      if (url.id) {
        await HealthChecksApi.updateUrl(newUrl)
        enqueueSnackbar(`Updated ${url.name}`, SnackbarVariants.SUCCESS)
      } else {
        await HealthChecksApi.addUrl(newUrl)
        enqueueSnackbar(`Added ${url.name}`, SnackbarVariants.SUCCESS)
      }
    } catch (error) {
      enqueueSnackbar(`Save failed ${url.name}`, SnackbarVariants.ERROR)
      console.error(error)
    }
    urlsRefetch()
  }

  if (urlsError) {
    return (
      <div style={{ margin: '10px', width: '100%' }}>
        <CenteredDiv>
          <ErrorMessage error={urlsError} />
        </CenteredDiv>
      </div>
    )
  }

  if (urlsLoading || !urlData) {
    return (
      <div style={{ margin: '10px', width: '100%' }}>
        <CenteredDiv>
          <CircularProgress />
        </CenteredDiv>
      </div>
    )
  }

  const columns = [
    {
      headerName: '',
      field: 'y',
      width: 70,
      renderCell: u => {
        return (
          <CenteredDiv>
            <Tooltip title='Edit'>
              <IconButton
                onClick={async e => {
                  await editUrl(u.row.id)
                }}
                color='primary'
                size='large'>
                <EditOutlined />
              </IconButton>
            </Tooltip>
          </CenteredDiv>
        )
      }
    },
    {
      headerName: 'Name',
      field: 'name',
      flex: 1
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 1
    },
    {
      headerName: 'type',
      field: 'type',
      flex: 1
    },
    {
      headerName: 'Dev URL',
      field: 'urlDev',
      flex: 1
    },
    {
      headerName: 'Int URL',
      field: 'urlInt',
      flex: 1
    },
    {
      headerName: 'Prod URL',
      field: 'urlProd',
      flex: 1
    },
    {
      headerName: '',
      field: 'x',
      width: 70,
      renderCell: u => {
        return (
          <CenteredDiv>
            <Tooltip title='Remove'>
              <IconButton
                onClick={async e => {
                  await deleteUrl(u.id)
                }}
                color='primary'
                size='large'>
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          </CenteredDiv>
        )
      }
    }
  ]

  return (
    <div>
      <PrimaryButton
        style={{ marginRight: '15px', marginBottom: '15px' }}
        onClick={async () => {
          setSelectedUrl({})
          setHealthCheckUrlModalOpen(true)
        }}>
        {'Add URL'}
      </PrimaryButton>
      <CustomDataGrid
        rows={urlData.map(p => {
          return { ...p, id: p.id }
        })}
        loading={urlsLoading}
        columns={[...columns]}
        cursor='pointer'
      />
      {selectedUrl ? (
        <HealthCheckUrlModal
          open={healthCheckUrlModalOpen}
          onClose={() => setHealthCheckUrlModalOpen(false)}
          onSave={url => saveUrl(url)}
          //styles={styles}
          healthCheckUrl={selectedUrl}
        />
      ) : (
        <></>
      )}
    </div>
  )
}
