import { CustomDataGrid } from '@wavetronix/common-components'
import React, { useState } from 'react'

export default function UserRolesTable({ roles, rolesLoading, renderSwitch }) {
  const [pageSize, setPageSize] = useState(100)

  const columns = [
    {
      field: 'override',
      headerName: 'Add',
      flex: 0.25,
      filterable: false,
      renderCell: data => renderSwitch(data)
    },
    {
      field: 'id',
      headerName: 'Role Name',
      flex: 0.5
    }
  ]

  return (
    <div style={{ width: '100%' }}>
      <CustomDataGrid
        rows={roles ? roles : []}
        autoHeight
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={p => setPageSize(p)}
        rowsPerPageOptions={[10, 20, 50, 100]}
        disableSelectionOnClick
        loading={rolesLoading}
        style={{ marginTop: '15px' }}
      />
    </div>
  )
}
