import axios from 'axios'
import { Buffer } from 'buffer'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class NexusConfigApi {
  getEnvInfo = async () => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.commoncompUserName}:${env.basicAuthentication.commoncompPassword}`
    ).toString('base64')

    return await axios
      .get(`${env.urls.nexusConfigURL}/config`, {
        headers: { Authorization: `Basic ${credentials}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  updateEnvInfo = async (msalInstance, accounts, envInfo) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.nexusConfigURL}/config`, envInfo, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new NexusConfigApi()
  }
  return instance
}

export default getInstance()
