import React, { useState, useMemo, useEffect } from 'react'
import {
  CustomSelect,
  RegularButton,
  CenteredDiv,
  ErrorMessage,
  PrimaryButton,
  SnackbarVariants
} from '@wavetronix/common-components'
import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { Grid, CircularProgress } from '@mui/material'
import GatekeeperApi from '../../api/GatekeeperApi'
import EditAppCardModal, { DEFAULT_APP } from './EditAppCardModal'
import AppCard from './AppCard'
import { AddOutlined } from '@mui/icons-material'
import { ENVIRONMENT_MAP, COMPANY_ENVIRONMENT_MAP } from '../../utils/environmentUtils.js'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { useSnackbar } from 'notistack'

const ReactGridLayout = WidthProvider(Responsive)

export default function UICardPage() {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [environment, setEnvironment] = useState('')
  const [company, setCompany] = useState('')
  const [editAppCardOpen, setEditAppCardOpen] = useState({ open: false, app: {} })
  const [createAppCardOpen, setCreateAppCardOpen] = useState({ open: false, app: {} })
  const [gridLoaded, setGridLoaded] = useState(false)
  const [cardLayout, setCardLayout] = useState({ lg: [], md: [], sm: [], xs: [] })

  const baseURL = useMemo(() => {
    return ENVIRONMENT_MAP[environment]
  }, [environment])

  const environmentOptions = useMemo(() => {
    if (company && company !== '') {
      return COMPANY_ENVIRONMENT_MAP[company]
    } else return []
  }, [company])

  const {
    data: UIData,
    isLoading: UILoading,
    error: UIError,
    refetch: UIRefetch
  } = useQuery({
    queryKey: ['uiData', baseURL, company],
    queryFn: async () => await GatekeeperApi.getUIs(instance, accounts, baseURL, company),
    enabled: !!baseURL && baseURL !== '' && !!company && company !== '' // Set 'enabled' to false if the parameter is null or an empty string
  })

  const onLayoutChange = (layout, layouts) => {
    if (gridLoaded === true) {
      setCardLayout(layouts)
    }
  }

  useEffect(() => {
    if (UIData) {
      let baseLayout = { lg: [], md: [], sm: [], xs: [] }

      for (let uiApp of UIData) {
        if (uiApp.lg) baseLayout.lg.push({ i: uiApp.id, x: uiApp.lg.x, y: uiApp.lg.y, h: uiApp.lg.h, w: uiApp.lg.w })
        if (uiApp.md) baseLayout.md.push({ i: uiApp.id, x: uiApp.md.x, y: uiApp.md.y, h: uiApp.md.h, w: uiApp.md.w })
        if (uiApp.sm) baseLayout.sm.push({ i: uiApp.id, x: uiApp.sm.x, y: uiApp.sm.y, h: uiApp.sm.h, w: uiApp.sm.w })
        if (uiApp.xs) baseLayout.xs.push({ i: uiApp.id, x: uiApp.xs.x, y: uiApp.xs.y, h: uiApp.xs.h, w: uiApp.xs.w })
      }

      setCardLayout(baseLayout)
      setGridLoaded(true)
    }
  }, [UIData])

  const saveUILayout = () => {
    let key = enqueueSnackbar('Saving UI Layout... please wait...', SnackbarVariants.LOADING)

    let uiMap = Object.entries(cardLayout).reduce((map, [key, value]) => {
      for (let uiApp of value) {
        if (uiApp.i in map) {
          map[uiApp.i] = { ...map[uiApp.i], [`${key}`]: { x: uiApp.x, y: uiApp.y, h: uiApp.h, w: uiApp.w } }
        } else {
          map[uiApp.i] = { [`${key}`]: { x: uiApp.x, y: uiApp.y, h: uiApp.h, w: uiApp.w } }
        }
      }

      return map
    }, {})

    let editAllPromise = UIData.map(uiApp => {
      let newAppInfo = {
        ...uiApp,
        lg: uiMap[uiApp.id].lg,
        md: uiMap[uiApp.id].md,
        sm: uiMap[uiApp.id].sm,
        xs: uiMap[uiApp.id].xs
      }

      return GatekeeperApi.updateUI(instance, accounts, baseURL, newAppInfo).catch(error => {
        enqueueSnackbar(`Error updating UI app card - ${error.message}`, SnackbarVariants.ERROR)
      })
    })

    Promise.all(editAllPromise)
      .then(res => enqueueSnackbar('Successfully updated layout', SnackbarVariants.SUCCESS))
      .catch(error => enqueueSnackbar(`Error updating layouts`, SnackbarVariants.ERROR))
      .finally(() => closeSnackbar(key))
  }

  if (UIError) {
    return (
      <div style={{ margin: '10px', width: '100%' }}>
        <Grid container>
          <Grid item md={6}>
            <CustomSelect
              style={{ width: '30%' }}
              label='Company'
              onChange={e => setCompany(e.target.value)}
              value={company}
              options={['wavetronix', 'islandradar', 'fathom']}
            />
            <CustomSelect
              style={{ width: '30%' }}
              label='Environment'
              onChange={e => setEnvironment(e.target.value)}
              value={environment}
              options={environmentOptions}
            />
          </Grid>
        </Grid>
        <CenteredDiv>
          <ErrorMessage error={UIError} />
        </CenteredDiv>
      </div>
    )
  }

  if (UILoading) {
    return (
      <div style={{ margin: '10px', width: '100%' }}>
        <Grid container>
          <Grid item md={6}>
            <CustomSelect
              style={{ width: '30%' }}
              label='Company'
              onChange={e => setCompany(e.target.value)}
              value={company}
              options={['wavetronix', 'islandradar', 'fathom']}
            />
            <CustomSelect
              style={{ width: '30%' }}
              label='Environment'
              onChange={e => setEnvironment(e.target.value)}
              value={environment}
              options={environmentOptions}
            />
          </Grid>
        </Grid>
        <CenteredDiv>
          <CircularProgress />
        </CenteredDiv>
      </div>
    )
  }

  return (
    <>
      {/* Need to show cards for each environment and be able to add, remove, and edit each */}
      <EditAppCardModal
        open={createAppCardOpen.open}
        onClose={() => setCreateAppCardOpen({ open: false, app: {} })}
        baseURL={baseURL}
        app={createAppCardOpen.app}
        env={environment}
        action='create'
        refetch={UIRefetch}
        folder={'apps'}
        company={company}
      />
      <EditAppCardModal
        open={editAppCardOpen.open}
        onClose={() => setEditAppCardOpen({ open: false, app: {} })}
        baseURL={baseURL}
        app={editAppCardOpen.app}
        env={environment}
        action='edit'
        refetch={UIRefetch}
        folder={'apps'}
        company={company}
      />
      <div style={{ margin: '10px', width: '100%' }}>
        <Grid container>
          <Grid item md={6} sm={12}>
            <CustomSelect
              style={{ width: '30%' }}
              label='Company'
              onChange={e => setCompany(e.target.value)}
              value={company}
              options={['wavetronix', 'islandradar', 'fathom']}
            />
            <CustomSelect
              style={{ width: '30%' }}
              label='Environment'
              onChange={e => setEnvironment(e.target.value)}
              value={environment}
              options={environmentOptions}
            />
          </Grid>
          {environment ? (
            <Grid item md={6} sm={12}>
              <PrimaryButton style={{ float: 'right' }} onClick={saveUILayout}>
                Save Layout
              </PrimaryButton>
              <RegularButton
                startIcon={<AddOutlined />}
                style={{ float: 'right' }}
                onClick={() => {
                  setCreateAppCardOpen({ open: true, app: DEFAULT_APP })
                }}>
                Create UI Card
              </RegularButton>
            </Grid>
          ) : null}
        </Grid>

        <div style={{ padding: '40px 40px 80px 40px' }}>
          {environment === '' || company === '' ? (
            <h1
              style={{
                margin: 'auto',
                textAlign: 'center',
                padding: 15,
                marginTop: 15
              }}>
              Select an environment and company.
            </h1>
          ) : gridLoaded === false ? (
            <CircularProgress />
          ) : UIData && UIData.length > 0 ? (
            <div>
              <ReactGridLayout
                className='layout'
                layouts={cardLayout}
                onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
                rowHeight={300}
                margin={[40, 40]}
                breakpoints={{ lg: 1400, md: 1200, sm: 500, xs: 370 }}
                cols={{ lg: 6, md: 4, sm: 2, xs: 1 }}>
                {UIData.map(item => (
                  <div style={{ width: '100%', height: '100%' }} key={item.id}>
                    <AppCard
                      env={environment === 'local' ? 'dev' : environment}
                      app={item}
                      key={item.id}
                      onClick={() => setEditAppCardOpen({ open: true, app: item })}
                      cursor={'pointer'}
                    />
                  </div>
                ))}
              </ReactGridLayout>
            </div>
          ) : (
            <div>No UI cards.</div>
          )}
        </div>
      </div>
    </>
  )
}
