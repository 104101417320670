import React from 'react'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import { DeleteOutlined } from '@mui/icons-material'
import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { CustomDataGrid, CenteredDiv, ErrorMessage, SnackbarVariants } from '@wavetronix/common-components'
import HealthChecksApi from '../../api/HealthChecksApi'
import { useSnackbar } from 'notistack'

export default function HealthCheckBuilds() {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: buildData,
    isLoading: buildsLoading,
    refetch: buildsRefetch,
    error: buildsError
  } = useQuery({ queryKey: ['builds'], queryFn: async () => await HealthChecksApi.getBuilds(instance, accounts) })

  if (buildsError) {
    return (
      <div style={{ margin: '10px', width: '100%' }}>
        <CenteredDiv>
          <ErrorMessage error={buildsError} />
        </CenteredDiv>
      </div>
    )
  }

  if (buildsLoading || !buildData) {
    return (
      <div style={{ margin: '10px', width: '100%' }}>
        <CenteredDiv>
          <CircularProgress />
        </CenteredDiv>
      </div>
    )
  }

  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 2
    },
    {
      headerName: 'Source',
      field: 'source',
      flex: 1
    },
    {
      headerName: 'Timestamp',
      field: 'timestamp',
      flex: 2
    },
    {
      headerName: '',
      field: 'x',
      width: 70,
      renderCell: u => {
        return (
          <CenteredDiv>
            <Tooltip title='Remove'>
              <IconButton
                onClick={async e => {
                  await deleteBuild(u.row.name)
                }}
                color='primary'
                size='large'>
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          </CenteredDiv>
        )
      }
    }
  ]

  const deleteBuild = async name => {
    try {
      await HealthChecksApi.deleteBuild(name)
      enqueueSnackbar(`Build deleted ${name}`, SnackbarVariants.SUCCESS)
    } catch (error) {
      enqueueSnackbar(`Delete failed for ${name}`, SnackbarVariants.ERROR)
    }
    buildsRefetch()
  }

  return (
    <div>
      <CustomDataGrid
        rows={buildData.map((p, i) => {
          return { ...p, id: i }
        })}
        loading={buildsLoading}
        columns={[...columns]}
        cursor='pointer'
      />
    </div>
  )
}
