import React, { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { CircularProgress, Tooltip, IconButton } from '@mui/material'
import { DeleteOutlined, EditOutlined } from '@mui/icons-material'
import { useQuery } from '@tanstack/react-query'
import { CustomDataGrid, SnackbarVariants, CenteredDiv, ErrorMessage, PrimaryButton } from '@wavetronix/common-components'
import RemindersApi from '../../api/RemindersApi'
import ExpirationReminderModal from './ExpirationReminderModal'
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import validator from 'validator'

export default function ExpirationRemindersPage() {
  const { instance, accounts } = useMsal()
  const [reminderModalOpen, setReminderModalOpen] = useState(false)
  const [selectedReminder, setSelectedReminder] = useState({})
  const [reminders, setReminders] = useState([])
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: reminderData,
    isLoading: remindersLoading,
    refetch: remindersRefetch,
    error: remindersError
  } = useQuery({ queryKey: ['reminders'], queryFn: async () => await RemindersApi.getReminders(instance, accounts) })

  useEffect(() => {
    if (reminderData) {
      let reminders = reminderData.map(r => {
        return { ...r, expireDate: dayjs(r.expireDate).format('YYYY-MM-DD') }
      })
      setReminders(reminders)
    }
  }, [setReminders, reminderData])

  const deleteReminder = async id => {
    try {
      await RemindersApi.deleteReminder(instance, accounts, id)
      enqueueSnackbar(`Reminder deleted`, SnackbarVariants.SUCCESS)
      remindersRefetch()
    } catch (error) {
      enqueueSnackbar(`Delete failed`, SnackbarVariants.ERROR)
      console.error(error)
    }
  }

  const editReminder = async id => {
    const reminder = reminderData.filter(u => u.id === id)[0]
    setSelectedReminder(reminder)
    setReminderModalOpen(true)
  }

  const processReminders = async () => {
    try {
      await RemindersApi.processReminders(instance, accounts)
      enqueueSnackbar(`Processesed Reminders`, SnackbarVariants.SUCCESS)
    } catch (error) {
      enqueueSnackbar(`Processesed Reminders failed`, SnackbarVariants.ERROR)
      console.error(error)
    }
  }

  const validateServiceConfig = async () => {
    try {
      await RemindersApi.validateServiceConfig(instance, accounts)
      enqueueSnackbar(`Service Config Valid`, SnackbarVariants.SUCCESS)
    } catch (error) {
      enqueueSnackbar(`Service Config Invalid`, SnackbarVariants.ERROR)
      console.error(error)
    }
  }

  const saveReminder = async reminder => {
    try {
      let newReminder = {
        ...reminder
      }
      if (reminder.id) {
        await RemindersApi.updateReminder(instance, accounts, newReminder)
        enqueueSnackbar(`Updated ${reminder.name}`, SnackbarVariants.SUCCESS)
      } else {
        await RemindersApi.addReminder(instance, accounts, newReminder)
        enqueueSnackbar(`Added ${reminder.name}`, SnackbarVariants.SUCCESS)
      }
    } catch (error) {
      enqueueSnackbar(`Save failed ${reminder.name}`, SnackbarVariants.ERROR)
      console.error(error)
    }
    remindersRefetch()
  }

  if (remindersError) {
    return (
      <div style={{ margin: '10px', width: '100%' }}>
        <CenteredDiv>
          <ErrorMessage error={remindersError} />
        </CenteredDiv>
      </div>
    )
  }

  if (!reminders || remindersLoading || !reminderData) {
    return (
      <div style={{ margin: '10px', width: '100%' }}>
        <CenteredDiv>
          <CircularProgress />
        </CenteredDiv>
      </div>
    )
  }

  const columns = [
    {
      headerName: '',
      field: 'y',
      width: 70,
      renderCell: u => {
        return (
          <CenteredDiv>
            <Tooltip title='Edit'>
              <IconButton
                onClick={async e => {
                  await editReminder(u.row.id)
                }}
                color='primary'
                size='large'>
                <EditOutlined />
              </IconButton>
            </Tooltip>
          </CenteredDiv>
        )
      }
    },
    {
      headerName: 'Name',
      field: 'name',
      flex: 1
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 2
    },
    {
      headerName: 'Category',
      field: 'category',
      flex: 1
    },
    {
      headerName: 'Expires',
      field: 'expireDate',
      flex: 1
    },
    {
      headerName: 'How to URL',
      field: 'howToUrl',
      width: 100,
      renderCell: u => {
        if (validator.isURL(u.row.howToUrl)) {
          return (
            <CenteredDiv>
              <Tooltip title={u.row.howToUrl}>
                <IconButton href={u.row.howToUrl} target='_blank' color='primary' size='large'>
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            </CenteredDiv>
          )
        } else {
          return <span></span>
        }
      }
    },
    {
      headerName: '',
      field: 'x',
      width: 70,
      renderCell: u => {
        return (
          <CenteredDiv>
            <Tooltip title='Remove'>
              <IconButton
                onClick={async e => {
                  await deleteReminder(u.id)
                }}
                color='primary'
                size='large'>
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          </CenteredDiv>
        )
      }
    }
  ]

  return (
    <div style={{ margin: '10px 20px 20px 20px', width: '100%' }}>
      <div>
        <div style={{ margin: '10px 0px 30px 0px' }}>
          <PrimaryButton
            style={{ marginRight: 15 }}
            onClick={async () => {
              setSelectedReminder({})
              setReminderModalOpen(true)
            }}>
            {'Add Reminder'}
          </PrimaryButton>

          <PrimaryButton
            style={{ marginRight: 15 }}
            onClick={async () => {
              await processReminders()
            }}>
            {'Process Reminders'}
          </PrimaryButton>

          <PrimaryButton
            style={{ marginRight: 15 }}
            onClick={async () => {
              await validateServiceConfig()
            }}>
            {'Validate Service Config'}
          </PrimaryButton>
        </div>
        <CustomDataGrid
          rows={reminders.map(p => {
            return { ...p, id: p.id }
          })}
          loading={remindersLoading}
          columns={[...columns]}
          cursor='pointer'
        />
        {selectedReminder ? (
          <ExpirationReminderModal
            open={reminderModalOpen}
            onClose={() => setReminderModalOpen(false)}
            onSave={reminder => saveReminder(reminder)}
            //styles={styles}
            reminder={selectedReminder}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
