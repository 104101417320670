export const localEnv = {
  runMode: 'prod',
  clientId: 'c0ae7aa7-284c-41ab-98ab-2294e2423683',
  urls: {
    remindersURL: `https://wtx-reminder-rest.azurewebsites.net`,
    healthCheckURL: `https://wtx-healthchecks-rest.azurewebsites.net`,
    nexusConfigURL: `https://wtx-nexusconfig-rest.wavetronix.com`,
    gatekeeperURL: `https://wtx-gatekeeper-rest.wavetronix.com`
  },
  basicAuthentication: {
    commoncompUserName: 'common-comp',
    commoncompPassword: 'fdlleiurie83uyrsdkflxieieikdjowiejieowieruolckc6mnvd',
    gatekeeperUserName: 'nexusconfig',
    gatekeeperPassword: 'sldkfjeie898wwoiefjwo39oadifu8weoieufokdfusodir',
    healthCheckUserName: 'healthcheck',
    healthCheckPassword: 'GEGBXFQBz8sz9Q9sutWUF9sDXK65z88E6eBSTJAV',
    remindersUserName: 'reminderTrigger',
    remindersPassword: 'X8Qf4tvR9w7Hz4DDQ4ga'
  }
}
