import React, { useState, useEffect, useMemo } from 'react'
import { CircularProgress } from '@mui/material'
import { useMsal } from '@azure/msal-react'
import { useSnackbar } from 'notistack'
import { CenteredDiv, ErrorMessage, PrimaryButton, SnackbarVariants } from '@wavetronix/common-components'
import { useQuery } from '@tanstack/react-query'
import NexusConfigApi from '../../api/NexusConfigApi'
import NexusConfigSection from './NexusConfigSection'
import SaveConfigModal from './SaveConfigModal'
import { isValidUrl } from '../../utils/validationChecks.js'

export default function NexusConfigPage() {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar } = useSnackbar()
  const [envInfo, setEnvInfo] = useState(null)
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const { data, isLoading, error } = useQuery({
    queryKey: ['configInfo'],
    queryFn: async () => await NexusConfigApi.getEnvInfo()
  })

  useEffect(() => {
    if (data) {
      setEnvInfo(data)
    }
  }, [data])

  const buttonsDisabled = useMemo(() => {
    if (envInfo) {
      //validate the envinfo object
      let validValues = Object.entries(envInfo).reduce((validList, info) => {
        for (let ids of Object.entries(info[1].clientIds)) {
          if (
            ids[0] === '' ||
            (ids[1].length === 36 && ids[1][8] === '-' && ids[1][13] === '-' && ids[1][18] === '-' && ids[1][23] === '-') ===
              false
          ) {
            validList.push(false)
          }
        }

        for (let ids of Object.entries(info[1].urls)) {
          if (ids[0] === '' || isValidUrl(ids[1]) === false) {
            validList.push(false)
          }
        }

        for (let ids of Object.entries(info[1].basicAuthentication)) {
          if (ids[0] === '' || ids[1] === '') {
            validList.push(false)
          }
        }

        return validList
      }, [])
      return validValues.includes(false)
    } else {
      return true
    }
  }, [envInfo])

  const saveConfiguration = async () => {
    await NexusConfigApi.updateEnvInfo(instance, accounts, envInfo)
      .then(
        result => {
          enqueueSnackbar('Nexus Configuration updated', SnackbarVariants.SUCCESS)
        },
        error => {
          enqueueSnackbar(`Error updating Nexus Configuration - ${error.message}`, SnackbarVariants.ERROR)
        }
      )
      .then(_ => {
        setSaveModalOpen(false)
      })
  }

  if (error) {
    return (
      <div style={{ margin: '10px', width: '100%' }}>
        <CenteredDiv>
          <ErrorMessage error={error} />
        </CenteredDiv>
      </div>
    )
  }
  if (isLoading) {
    return (
      <div style={{ margin: '10px', width: '100%' }}>
        <CenteredDiv>
          <CircularProgress />
        </CenteredDiv>
      </div>
    )
  }

  return (
    <>
      <SaveConfigModal
        open={saveModalOpen}
        onClose={() => setSaveModalOpen(false)}
        envInfo={envInfo}
        onSave={saveConfiguration}
      />
      <div style={{ margin: '10px', width: '100%' }}>
        <PrimaryButton disabled={buttonsDisabled} style={{ marginBottom: '10px' }} onClick={() => setSaveModalOpen(true)}>
          Save Configuration
        </PrimaryButton>
        {envInfo ? (
          Object.keys(envInfo).map(environment => (
            <NexusConfigSection
              key={environment}
              environment={environment}
              config={envInfo[environment]}
              setEnvInfo={setEnvInfo}
            />
          ))
        ) : (
          <CenteredDiv>
            <CircularProgress />
          </CenteredDiv>
        )}
      </div>
    </>
  )
}
