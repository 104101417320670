export const ENVIRONMENT_MAP = {
  local: 'http://localhost:5001',
  dev: 'https://wtx-gatekeeper-rest.wtxdev.com',
  int: 'https://wtx-gatekeeper-rest.wtxint.com',
  prod: 'https://wtx-gatekeeper-rest.wavetronix.com'
}

export const COMPANY_ENVIRONMENT_MAP = {
  wavetronix: [
    //'local',
    'dev',
    'int',
    'prod'
  ],
  islandradar: [
    //'local',
    'dev',
    'prod'
  ],
  fathom: [
    //'local',
    'prod'
  ]
}

export const ENVIRONMENT_LIST = [
  //'local',
  'dev',
  'int',
  'prod'
]
