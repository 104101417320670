import { DialogContent, FormControlLabel } from '@mui/material'
import { SlideUpDialog, PrimaryButton, CustomCheckbox } from '@wavetronix/common-components'
import { useState } from 'react'
export default function SaveConfigModal({ open, onClose, envInfo, onSave }) {
  const [checked, setChecked] = useState(false)
  return (
    <SlideUpDialog
      fullScreen={false}
      open={open}
      onClose={() => {
        setChecked(false)
        onClose()
      }}
      title={'Nexus Configuration'}
      actions={
        <>
          <PrimaryButton
            style={{ width: '100px' }}
            disabled={checked === false}
            onClick={() => {
              onSave()
              setChecked(false)
            }}>
            Save
          </PrimaryButton>
        </>
      }>
      <DialogContent>
        <textarea value={JSON.stringify(envInfo, null, 2)} rows={40} style={{ width: '100%' }} readOnly />

        <FormControlLabel
          control={
            <CustomCheckbox
              checked={checked}
              onChange={e => {
                setChecked(e.target.checked)
              }}
            />
          }
          label='Verify Configuration'
        />
      </DialogContent>
    </SlideUpDialog>
  )
}
