import {
  SlideUpDialog,
  PrimaryButton,
  SnackbarVariants,
  DeleteButton,
  getResourceUrl,
  useWtxLocalization
} from '@wavetronix/common-components'
import { useState, useEffect, useMemo } from 'react'
import { Grid, TextField, DialogContent, Switch, CircularProgress } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useMsal } from '@azure/msal-react'
import UserRolesTable from './UserRolesTable'
import GatekeeperApi from '../../api/GatekeeperApi'
import NexusAssetsApi from '../../api/NexusAssetsApi'
import DeleteConfirmationModal from '../DeleteConfirmationModal'
import { isValidUrl, isValidColor } from '../../utils/validationChecks.js'
import ImageSelect from '../ImageSelect'

const classes = {
  buttonBase: {
    display: 'block',
    width: '100%',
    height: '100%',
    color: 'transparent'
  },
  imageBase: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 'auto'
  },
  titleText: {
    fontFamily: 'Proxima Nova',
    fontWeight: 'bold',
    fontSize: 18,
    display: 'inline',
    verticalAlign: 'middle',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    color: 'white'
  },
  titleIcon: {
    width: '12em',
    height: '12em'
  },
  appCard: {
    borderRadius: '20px',
    boxShadow: '3px 0px 53px -20px rgba(0,0,0,0.6)',
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  }
}

export const DEFAULT_APP = {
  appName: '',
  color: '',
  icon: '',
  id: '',
  allowedRoles: []
}

const nullOrWhiteSpace = value => {
  if (value && value !== '') return false
  else return true
}

export default function EditAppCardModal({ app, baseURL, open, onClose, env, action, refetch, folder, company }) {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar } = useSnackbar()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [appInfo, setAppInfo] = useState(DEFAULT_APP)
  const [isUploading, setIsUploading] = useState(false)
  const [roleMap, setRoleMap] = useState({})
  let localizedStrings = useWtxLocalization()

  const getAppName = app => {
    let appName = app
    if (Object.keys(localizedStrings).includes(app)) {
      appName = localizedStrings[app]
    }
    return appName
  }

  const { data: rolesData, isLoading: rolesLoading } = useQuery({
    queryKey: ['roles', baseURL],
    queryFn: async () => await GatekeeperApi.getRoles(instance, accounts, baseURL),
    enabled: !!baseURL && baseURL !== '' // Set 'enabled' to false if the parameter is null or an empty string
  })

  const { data: assetsData, isLoading: assetsLoading } = useQuery({
    queryKey: ['assets', baseURL, folder],
    queryFn: async () => await NexusAssetsApi.getAssets(instance, accounts, baseURL, folder),
    enabled: !!baseURL && baseURL !== '' && !!folder && folder !== '' // Set 'enabled' to false if the parameter is null or an empty string
  })

  useEffect(() => {
    if (app && rolesData) {
      setAppInfo(app)
      let roleM = {}
      for (let role of rolesData) {
        if (app.allowedRoles) roleM[role.id] = app.allowedRoles.includes(role.id)
      }

      setRoleMap(roleM)
    }
  }, [app, rolesData])

  const validAppInfo = useMemo(() => {
    if (appInfo) {
      if (nullOrWhiteSpace(appInfo.appName)) return false
      else if (isValidColor(appInfo.color) === false) return false
      else if (isValidUrl(appInfo.url) === false) return false
      else if (nullOrWhiteSpace(appInfo.icon) || (assetsData && assetsData.includes(appInfo.icon) === false)) return false
      else return true
    } else return false
  }, [appInfo, assetsData])

  const editUI = async () => {
    let newAppInfo = { ...appInfo, allowedRoles: Object.keys(roleMap).filter(role => roleMap[role] === true) }

    await GatekeeperApi.updateUI(instance, accounts, baseURL, newAppInfo).then(
      _ => {
        enqueueSnackbar('Successfully updated UI app card', SnackbarVariants.SUCCESS)
      },
      error => {
        enqueueSnackbar(`Error updating UI app card - ${error.message}`, SnackbarVariants.ERROR)
      }
    )
  }

  const createUI = async () => {
    let newAppInfo = { ...appInfo, allowedRoles: Object.keys(roleMap).filter(role => roleMap[role] === true) }

    await GatekeeperApi.createUI(instance, accounts, baseURL, newAppInfo, company).then(
      _ => {
        enqueueSnackbar('Successfully updated UI app card', SnackbarVariants.SUCCESS)
      },
      error => {
        enqueueSnackbar(`Error updating UI app card - ${error.message}`, SnackbarVariants.ERROR)
      }
    )
  }

  const deleteUI = async uiId => {
    await GatekeeperApi.deleteUI(instance, accounts, baseURL, uiId).then(
      _ => {
        enqueueSnackbar('Successfully deleted UI app card', SnackbarVariants.SUCCESS)
      },
      error => {
        enqueueSnackbar(`Error deleting UI app card - ${error.message}`, SnackbarVariants.ERROR)
      }
    )
  }

  return (
    <>
      <DeleteConfirmationModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        message={
          <>
            <p>Verify that you want to delete this UI App Card:</p>
            <p>{appInfo.appName}</p>
          </>
        }
        onDelete={async () => {
          setIsUploading(true)
          await deleteUI(appInfo.id)
          await refetch()
          setIsUploading(false)
          setDeleteModalOpen(false)
          onClose()
        }}
      />
      <SlideUpDialog
        open={open}
        maxWidth={'lg'}
        onClose={() => {
          setAppInfo(DEFAULT_APP)
          onClose()
        }}
        title={'Edit App Card'}
        actions={
          <>
            <PrimaryButton
              disabled={isUploading || validAppInfo === false || !company || company === ''}
              style={{ width: '100px' }}
              onClick={async () => {
                setIsUploading(true)
                if (action === 'create') {
                  await createUI()
                } else {
                  await editUI()
                }
                setIsUploading(false)
                await refetch()
                onClose()
              }}>
              {action === 'create' ? 'Create' : 'Update'}
            </PrimaryButton>
          </>
        }>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                error={nullOrWhiteSpace(appInfo.appName) === true}
                style={{ marginTop: '10px', width: '100%' }}
                label={'App Name'}
                size='small'
                value={appInfo.appName ? appInfo.appName : ''}
                variant='outlined'
                onChange={e => setAppInfo(f => ({ ...f, appName: e.target.value }))}
              />
              <TextField
                error={isValidColor(appInfo.color) === false}
                style={{ marginTop: '10px', width: '100%' }}
                label={'Color'}
                size='small'
                value={appInfo.color ? appInfo.color : ''}
                variant='outlined'
                onChange={e => setAppInfo(f => ({ ...f, color: e.target.value }))}
              />
              <TextField
                disabled={true}
                style={{ marginTop: '10px', width: '100%' }}
                label={'Company'}
                size='small'
                value={company ? company : ''}
                variant='outlined'
              />
              <TextField
                error={isValidUrl(appInfo.url) === false}
                style={{ marginTop: '10px', width: '100%' }}
                label={'URL'}
                size='small'
                value={appInfo.url ? appInfo.url : ''}
                variant='outlined'
                onChange={e => setAppInfo(f => ({ ...f, url: e.target.value }))}
              />
              <div style={{ height: '360px', marginTop: '25px' }}>
                <div style={{ ...classes.appCard, cursor: 'pointer' }}>
                  <div style={{ height: '80%', ...classes.imageBase }}>
                    {assetsLoading || env === '' ? (
                      <CircularProgress />
                    ) : (
                      <ImageSelect
                        imageStyle={{
                          width: '12em',
                          height: '12em'
                        }}
                        label={'Icon'}
                        options={assetsData ? assetsData : []}
                        value={assetsData && appInfo.icon && assetsData.includes(appInfo.icon) ? appInfo.icon : ''}
                        onChange={value => setAppInfo(f => ({ ...f, icon: value }))}
                        srcPath={`${env === 'local' ? getResourceUrl('dev') : getResourceUrl(env)}/images/apps`}
                        env={env}
                        folder={'apps'}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      backgroundColor: appInfo.color,
                      height: '20%',
                      borderRadius: '0px 0px 20px 20px',
                      ...classes.imageBase
                    }}>
                    <div style={classes.titleText}>{getAppName(appInfo.appName)}</div>
                  </div>
                </div>
              </div>
              {action === 'create' ? null : (
                <DeleteButton
                  style={{ width: '100%', marginTop: '25px' }}
                  onClick={() => setDeleteModalOpen(true)}
                  disabled={isUploading || validAppInfo === false}>
                  Delete App Card
                </DeleteButton>
              )}
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <div>
                <UserRolesTable
                  roles={rolesData}
                  rolesLoading={rolesLoading}
                  renderSwitch={data => {
                    return (
                      <Switch
                        checked={roleMap && roleMap[data.row.id] ? roleMap[data.row.id] : false}
                        color={roleMap && roleMap[data.row.id] && roleMap[data.row.id] === true ? 'primary' : 'secondary'}
                        onChange={e => {
                          if (roleMap[data.row.id] === true) {
                            setRoleMap(a => ({ ...a, [data.row.id]: false }))
                          } else {
                            setRoleMap(a => ({ ...a, [data.row.id]: true }))
                          }
                        }}
                      />
                    )
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </SlideUpDialog>
    </>
  )
}
