import axios from 'axios'
import { Buffer } from 'buffer'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class GatekeeperApi {
  getUIs = async (msalInstance, accounts, baseURL, company) => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.gatekeeperUserName}:${env.basicAuthentication.gatekeeperPassword}`
    ).toString('base64')

    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${baseURL}/${company}/api/userinterfaces`, {
        headers: { Authorization: `Basic ${credentials}`, CustomGatekeeperToken: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getRoles = async (msalInstance, accounts, baseURL) => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.gatekeeperUserName}:${env.basicAuthentication.gatekeeperPassword}`
    ).toString('base64')

    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${baseURL}/api/roles/gojira`, {
        headers: { Authorization: `Basic ${credentials}`, CustomGatekeeperToken: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  createUI = async (msalInstance, accounts, baseURL, uiInfo, company) => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.gatekeeperUserName}:${env.basicAuthentication.gatekeeperPassword}`
    ).toString('base64')

    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${baseURL}/${company}/api/userinterfaces`, uiInfo, {
        headers: { Authorization: `Basic ${credentials}`, CustomGatekeeperToken: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  updateUI = async (msalInstance, accounts, baseURL, uiInfo) => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.gatekeeperUserName}:${env.basicAuthentication.gatekeeperPassword}`
    ).toString('base64')

    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .put(`${baseURL}/api/userinterfaces`, uiInfo, {
        headers: { Authorization: `Basic ${credentials}`, CustomGatekeeperToken: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  deleteUI = async (msalInstance, accounts, baseURL, uiId) => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.gatekeeperUserName}:${env.basicAuthentication.gatekeeperPassword}`
    ).toString('base64')

    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .delete(`${baseURL}/api/userinterfaces/${uiId}`, {
        headers: { Authorization: `Basic ${credentials}`, CustomGatekeeperToken: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new GatekeeperApi()
  }
  return instance
}

export default getInstance()
