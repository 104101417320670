import axios from 'axios'
import { Buffer } from 'buffer'
import { env } from '../index.js'

let instance = null

class HealthChecksApi {
  getBuilds = async () => {
    let token = Buffer.from(
      `${env.basicAuthentication.healthCheckUserName}:${env.basicAuthentication.healthCheckPassword}`
    ).toString('base64')

    const baseURL = `${env.urls.healthCheckURL}/api/v1/healthcheck/builds`

    return await axios
      .get(baseURL, {
        headers: { Authorization: `Basic ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  deleteBuild = async name => {
    let token = Buffer.from(
      `${env.basicAuthentication.healthCheckUserName}:${env.basicAuthentication.healthCheckPassword}`
    ).toString('base64')

    const baseURL = `${env.urls.healthCheckURL}/api/v1/healthcheck/builds?name=${name}`

    await axios.delete(baseURL, {
      headers: { Authorization: `Basic ${token}` },
      timeout: 30000
    })
  }

  getUrls = async () => {
    let token = Buffer.from(
      `${env.basicAuthentication.healthCheckUserName}:${env.basicAuthentication.healthCheckPassword}`
    ).toString('base64')

    const baseURL = `${env.urls.healthCheckURL}/api/v1/healthcheck/urls`

    let healthCheckUrls = (
      await axios.get(baseURL, {
        headers: { Authorization: `Basic ${token}` },
        timeout: 30000
      })
    ).data

    return healthCheckUrls.map(u => {
      return {
        ...u,
        urlDev: u.urls.dev,
        urlInt: u.urls.int,
        urlProd: u.urls.prod
      }
    })
  }

  deleteUrl = async name => {
    let token = Buffer.from(
      `${env.basicAuthentication.healthCheckUserName}:${env.basicAuthentication.healthCheckPassword}`
    ).toString('base64')

    const baseURL = `${env.urls.healthCheckURL}/api/v1/healthcheck/url/${name}`

    await axios.delete(baseURL, {
      headers: { Authorization: `Basic ${token}` },
      timeout: 30000
    })
  }

  addUrl = async healthCheckUrl => {
    let token = Buffer.from(
      `${env.basicAuthentication.healthCheckUserName}:${env.basicAuthentication.healthCheckPassword}`
    ).toString('base64')

    const baseURL = `${env.urls.healthCheckURL}/api/v1/healthcheck/url`

    await axios.post(baseURL, healthCheckUrl, {
      headers: { Authorization: `Basic ${token}` },
      timeout: 30000
    })
  }

  updateUrl = async healthCheckUrl => {
    let token = Buffer.from(
      `${env.basicAuthentication.healthCheckUserName}:${env.basicAuthentication.healthCheckPassword}`
    ).toString('base64')

    const baseURL = `${env.urls.healthCheckURL}/api/v1/healthcheck/url`

    await axios.put(baseURL, healthCheckUrl, {
      headers: { Authorization: `Basic ${token}` },
      timeout: 30000
    })
  }

  clearExpiredRunningBuilds = async () => {
    let token = Buffer.from(
      `${env.basicAuthentication.healthCheckUserName}:${env.basicAuthentication.healthCheckPassword}`
    ).toString('base64')

    const baseURL = `${env.urls.healthCheckURL}/api/v1/healthcheck/builds/clear`

    await axios.post(baseURL, null, {
      headers: { Authorization: `Basic ${token}` },
      timeout: 30000
    })
  }

  getPausedBuilds = async () => {
    let token = Buffer.from(
      `${env.basicAuthentication.healthCheckUserName}:${env.basicAuthentication.healthCheckPassword}`
    ).toString('base64')

    const baseURL = `${env.urls.healthCheckURL}/api/v1/healthcheck/pausedbuilds`

    return (
      await axios.get(baseURL, {
        headers: { Authorization: `Basic ${token}` },
        timeout: 30000
      })
    ).data
  }

  pauseBuild = async (name, isPaused) => {
    let token = Buffer.from(
      `${env.basicAuthentication.healthCheckUserName}:${env.basicAuthentication.healthCheckPassword}`
    ).toString('base64')

    const baseURL = `${env.urls.healthCheckURL}/api/v1/healthcheck/pausedbuilds?name=${name}&isPaused=${isPaused}`

    await axios.post(baseURL, null, {
      headers: { Authorization: `Basic ${token}` },
      timeout: 30000
    })
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new HealthChecksApi()
  }
  return instance
}

export default getInstance()
