import React from 'react'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import { PauseOutlined, PlayArrowOutlined } from '@mui/icons-material'
import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { CustomDataGrid, CenteredDiv, ErrorMessage, SnackbarVariants, PrimaryButton } from '@wavetronix/common-components'
import HealthChecksApi from '../../api/HealthChecksApi'
import { useSnackbar } from 'notistack'

export default function HealthChecOtherOptions() {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: buildData,
    isLoading: buildsLoading,
    refetch: buildsRefetch,
    error: buildsError
  } = useQuery({ queryKey: ['builds'], queryFn: async () => await HealthChecksApi.getBuilds() })

  const {
    data: pausedBuilds,
    isLoading: pausedBuildsLoading,
    refetch: pausedBuildsRefetch,
    error: pausedBuildsError
  } = useQuery({ queryKey: ['pausedBuilds'], queryFn: async () => await HealthChecksApi.getPausedBuilds() })

  const pauseBuild = async (name, isPaused) => {
    try {
      await HealthChecksApi.pauseBuild(name, isPaused)
      enqueueSnackbar(`Build paused`, SnackbarVariants.SUCCESS)
      buildsRefetch()
      pausedBuildsRefetch()
    } catch (error) {
      enqueueSnackbar(`Pause build failed`, SnackbarVariants.ERROR)
      console.error(error)
    }
  }

  if (buildsError) {
    return (
      <div style={{ margin: '10px', width: '100%' }}>
        <CenteredDiv>
          <ErrorMessage error={buildsError} />
        </CenteredDiv>
      </div>
    )
  }

  if (pausedBuildsError) {
    return (
      <div style={{ margin: '10px', width: '100%' }}>
        <CenteredDiv>
          <ErrorMessage error={buildsError} />
        </CenteredDiv>
      </div>
    )
  }

  if (buildsLoading || !buildData || pausedBuildsLoading || !pausedBuilds) {
    return (
      <div style={{ margin: '10px', width: '100%' }}>
        <CenteredDiv>
          <CircularProgress />
        </CenteredDiv>
      </div>
    )
  }

  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 1
    },
    {
      headerName: 'Source',
      field: 'source',
      flex: 1
    },
    {
      headerName: '',
      field: 'x',
      width: 70,
      renderCell: u => {
        return (
          <CenteredDiv>
            {!pausedBuilds.includes(u.row.name) ? (
              <Tooltip title='Pause'>
                <IconButton
                  onClick={async e => {
                    await pauseBuild(u.row.name, true)
                  }}
                  color='primary'
                  size='large'>
                  <PauseOutlined />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title='Resume'>
                <IconButton
                  onClick={async e => {
                    await pauseBuild(u.row.name, false)
                  }}
                  color='primary'
                  size='large'>
                  <PlayArrowOutlined />
                </IconButton>
              </Tooltip>
            )}
          </CenteredDiv>
        )
      }
    }
  ]

  const clearExpiredRunningBuilds = async () => {
    try {
      await HealthChecksApi.clearExpiredRunningBuilds(instance, accounts)
      enqueueSnackbar(`Expired Builds Cleared`, SnackbarVariants.SUCCESS)
    } catch (error) {
      enqueueSnackbar(`Clearing Expired Builds failed`, SnackbarVariants.ERROR)
      console.error(error)
    }
  }

  return (
    <div>
      <Tooltip title='Clear expired running build statuses'>
        <PrimaryButton style={{ marginRight: '15px', marginBottom: '15px' }} onClick={async () => clearExpiredRunningBuilds()}>
          {'Clear Build Status'}
        </PrimaryButton>
      </Tooltip>
      <CustomDataGrid
        rows={buildData.map((p, i) => {
          return { ...p, id: i }
        })}
        loading={buildsLoading}
        columns={[...columns]}
        cursor='pointer'
      />
    </div>
  )
}
