import { Card, CardHeader, CardContent, Grid } from '@mui/material'
import { CenteredDiv, RegularButton, CustomAccordion } from '@wavetronix/common-components'
import { AddOutlined } from '@mui/icons-material'
import NexusConfigLine from './NexusConfigLine'
import URLHelperIcon from '../URLHelperIcon'
import { isValidUrl } from '../../utils/validationChecks.js'

const classes = {
  accordianSummary: {
    width: '100%',
    borderRadius: '5px',
    minHeight: '40px',
    color: '#00c473'
  },
  card: {
    margin: '10px'
  },
  cardHeader: {
    fontSize: '15px'
  },
  textField: {
    width: '42%',
    margin: '5px'
  },
  header: {
    margin: '10px'
  }
}

export default function NexusConfigSection({ environment, config, setEnvInfo }) {
  return (
    <>
      <div>
        {config ? (
          <CustomAccordion
            defaultExpanded={false}
            disableGutters
            titleStyle={classes.accordianSummary}
            title={
              <div style={{ display: 'block' }}>
                <h2 style={classes.header}>
                  {environment}
                  <URLHelperIcon
                    message={
                      <>
                        <p>URLs containing {environment} will look for configuration in this section.</p>
                        <p>These rules are based off the naming convention: UIAppName.UIEnvironment.com</p>
                      </>
                    }
                  />
                </h2>
                <h5 style={classes.header}>Run Mode - {config.runMode}</h5>
              </div>
            }>
            <Grid container>
              <Grid item xl={4} lg={6}>
                <Card sx={classes.card}>
                  <CardHeader subheader={'Client Ids'} />
                  <CardContent>
                    <div style={{ display: 'flex' }}>
                      <div style={classes.textField}>
                        <CenteredDiv>
                          Frontend URL Hostname{' '}
                          <URLHelperIcon
                            message={
                              <>
                                <p>This value must match the URL hostname.</p>
                                <p>ie. nexus.{environment}.com will search for the client id matching to nexus</p>
                              </>
                            }
                          />
                        </CenteredDiv>
                      </div>
                      <div style={classes.textField}>
                        <CenteredDiv>B2C App Client Id</CenteredDiv>
                      </div>
                    </div>
                    {config.clientIds
                      ? Object.entries(config.clientIds).map((info, i) => {
                          return (
                            <NexusConfigLine
                              key={i}
                              info={info}
                              environment={environment}
                              setEnvInfo={setEnvInfo}
                              property={'clientIds'}
                              textFieldCheck={value => {
                                if (
                                  value.length === 36 &&
                                  value[8] === '-' &&
                                  value[13] === '-' &&
                                  value[18] === '-' &&
                                  value[23] === '-'
                                ) {
                                  return false
                                } else {
                                  return true
                                }
                              }}
                            />
                          )
                        })
                      : '...Loading'}
                    <CenteredDiv>
                      <RegularButton
                        style={{ marginTop: '15px' }}
                        startIcon={<AddOutlined />}
                        onClick={() => {
                          setEnvInfo(f => ({
                            ...f,
                            [environment]: { ...f[environment], clientIds: { ...f[environment].clientIds, '': '' } }
                          }))
                        }}>
                        Client Id
                      </RegularButton>
                    </CenteredDiv>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xl={4} lg={6}>
                <Card sx={classes.card}>
                  <CardHeader subheader={'Backend URLs'} />
                  <CardContent>
                    <div style={{ display: 'flex' }}>
                      <div style={classes.textField}>
                        <CenteredDiv>URL Variable Name</CenteredDiv>
                      </div>
                      <div style={classes.textField}>
                        <CenteredDiv>Backend URL</CenteredDiv>
                      </div>
                    </div>
                    {config.urls
                      ? Object.entries(config.urls).map((info, i) => {
                          return (
                            <NexusConfigLine
                              key={i}
                              info={info}
                              environment={environment}
                              setEnvInfo={setEnvInfo}
                              property={'urls'}
                              textFieldCheck={value => {
                                if (isValidUrl(value) === true) {
                                  return false
                                } else {
                                  return true
                                }
                              }}
                            />
                          )
                        })
                      : '...Loading'}
                    <CenteredDiv>
                      <RegularButton
                        style={{ marginTop: '15px' }}
                        startIcon={<AddOutlined />}
                        onClick={() => {
                          setEnvInfo(f => ({
                            ...f,
                            [environment]: { ...f[environment], urls: { ...f[environment].urls, '': '' } }
                          }))
                        }}>
                        Backend URL
                      </RegularButton>
                    </CenteredDiv>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xl={4} lg={6}>
                <Card sx={classes.card}>
                  <CardHeader subheader={'Basic Authentication'} />
                  <CardContent>
                    <div style={{ display: 'flex' }}>
                      <div style={classes.textField}>
                        <CenteredDiv>Authentication Variable Name</CenteredDiv>
                      </div>
                      <div style={classes.textField}>
                        <CenteredDiv>Authentication Value</CenteredDiv>
                      </div>
                    </div>
                    {config.basicAuthentication
                      ? Object.entries(config.basicAuthentication).map((info, i) => {
                          return (
                            <NexusConfigLine
                              key={i}
                              info={info}
                              environment={environment}
                              setEnvInfo={setEnvInfo}
                              property={'basicAuthentication'}
                              textFieldCheck={value => {
                                if (value !== '') {
                                  return false
                                } else {
                                  return true
                                }
                              }}
                            />
                          )
                        })
                      : '...Loading'}
                    <CenteredDiv>
                      <RegularButton
                        style={{ marginTop: '15px' }}
                        startIcon={<AddOutlined />}
                        onClick={() => {
                          setEnvInfo(f => ({
                            ...f,
                            [environment]: {
                              ...f[environment],
                              basicAuthentication: { ...f[environment].basicAuthentication, '': '' }
                            }
                          }))
                        }}>
                        Basic Authentication
                      </RegularButton>
                    </CenteredDiv>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CustomAccordion>
        ) : (
          '...Loading'
        )}
      </div>
    </>
  )
}
